export default {
    time: {
        month: [
            'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni',
            'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'
        ],
        monthAbbr: [
            'Jan','Feb','Mar','Apr','Maj','Jun','Jul','Aug','Sep','Okt','Nov','Dec'
        ],
        dayOfWeek: [
            'Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'
        ],
        dayOfWeekAbbr: [
            'Sön','Mån','Tis','Ons','Tor','Fre','Lör'
        ]
    },
    legend: {
        selector: {
            all: 'Alla',
            inverse: 'Omvänd'
        }
    },
    toolbox: {
        brush: {
            title: {
                rect: 'Box Select',
                polygon: 'Lasso Select',
                lineX: 'Välj horisontellt',
                lineY: 'Välj vertikalt',
                keep: 'Behåll urval',
                clear: 'Rensa val'
            }
        },
        dataView: {
            title: 'Datavy',
            lang: ['Datavy', 'Stäng', 'Uppdatera']
        },
        dataZoom: {
            title: {
                zoom: 'Zoom',
                back: 'Zoomåterställning'
            }
        },
        magicType: {
            title: {
                line: 'Byt till linjediagram',
                bar: 'Byt till stapeldiagram',
                stack: 'Stack',
                tiled: 'Kakel'
            }
        },
        restore: {
            title: 'Återställa'
        },
        saveAsImage: {
            title: 'Spara som bild',
            lang: ['Högerklicka för att spara bild']
        }
    },
    series: {
        typeNames: {
            pie: 'Cirkeldiagram',
            bar: 'Stapeldiagram',
            line: 'Linjediagram',
            scatter: 'Scatter plot',
            effectScatter: 'Ripple scatter plot',
            radar: 'Radardiagram',
            tree: 'Träd',
            treemap: 'Treemap',
            boxplot: 'Boxplot',
            candlestick: 'Ljusstake',
            k: 'K linjediagram',
            heatmap: 'Heat map',
            map: 'Map',
            parallel: 'Parallell koordinatkarta',
            lines: 'Linjediagram',
            graph: 'Relationsdiagram',
            sankey: 'Sankey diagram',
            funnel: 'Trattdiagram',
            gauge: 'Gauge',
            pictorialBar: 'Pictorial bar',
            themeRiver: 'Theme River Map',
            sunburst: 'Sunburst'
        }
    },
    aria: {
        general: {
            withTitle: 'Detta är ett diagram om "{title}"',
            withoutTitle: 'Detta är ett diagram'
        },
        series: {
            single: {
                prefix: '',
                withName: ' med typen {seriesType} med namnet {seriesName}.',
                withoutName: ' med typen {seriesType}.'
            },
            multiple: {
                prefix: '. Den består av {seriesCount} serieantal.',
                withName: ' {seriesId}-serien är en {seriesType} som representerar {seriesName}.',
                withoutName: ' {seriesId}-serien är en {seriesType}.',
                separator: {
                    middle: '',
                    end: ''
                }
            }
        },
        data: {
            allData: 'Datan är som följer:',
            partialData: 'De första {displayCnt} objekten är: ',
            withName: 'data för {name} är {value}',
            withoutName: '{value}',
            separator: {
                middle: ', ',
                end: '. '
            }
        }
    }
};
