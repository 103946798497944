import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from 'ag-grid-community';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import dayjs from 'dayjs';

export type CircDetailAll = {
    Year: string;
    Category: string;
    Type: string;
    Count: number;
};

export type CircDetail = {
    Age: string;
    Category: string;
    Count: number;
    MatType: string;
    Year: string;
    collection: string;
};

export type WeedRecord = {
    Abbr: string;
    Age: string;
    BrowseAuthor: string;
    BrowseTitle: string;
    CallNumber: string;
    Category: string;
    Circulates: number;
    Code: number;
    Collection: string;
    Count: number;
    Dewey: string;
    Hotness: number;
    ItemRecordID: string;
    ItemStatusDescr: string;
    LCC: string;
    MARCPubDateOne: string;
    MARCTOMDescription: string;
    MatType: string;
    MaterialTypeDescription: string;
    SortISBN: string;
    SortUpc: string;
    TopCategory: string;
    bisacCode: string;
    id: number;
    insert_date: string;
    itembarcode: string;
    itembranchabbr: string;
    itemcreationdate: string;
    itemid: string;
    itemlifetimecirccount: number;
    itemytdcirccount: number;
    lastcheckoutrenewdate: string;
    libraryid: number;
    literal: string;
    name: string;
};

export interface Consortium {
    id: number;
    name: string;
    populationServed: number;
    coordinates: string;
}

export interface AccountGroup {
    id: number;
    name: string;
    populationServed: number;
    coordinates: string;
    libraryId?: number[];
    branchId?: number[];
}

export interface Library {
    name: string;
    id: number;
    cid?: number;
    cname?: string | null;
    groupId?: number;
    groupName?: string | null;
    settings: string;
    fiscalYear: string;
    q1?: dayjs.Dayjs;
    q2?: dayjs.Dayjs;
    q3?: dayjs.Dayjs;
    q4?: dayjs.Dayjs;
    coordinates?: string;
    censusLayer?: string;
    populationServed: number;
    cPopulationServed?: number;
    cCoordinates?: string;
    lgPopulationServed?: number;
    lgCoordinates?: string;
    lgBranchId?: number;
    patronsInServiceArea?: number;
}

export type CirculationTrend = {
    'Year 1': string;
    Year: number;
    Date: number;
    Count: number;
};

export interface GetCircByMonth {
    expired: boolean;
    data: [CirculationTrend[], { CircPreviousYear: number }[], { CircCurrentYear: number }[]];
}

interface SearchBiblio {
    lastLocation: string;
    start: string;
    end: string;
    collection?: string;
    returnValue?: string;
    deiCategory?: string;
}

export type MonitorChange = {
    currentLibrary: number;
    currentBranch: string;
};

export type CriteriaChange = { currentAge: string; Category: string; MatType: string; deiCategory: string };

export type Branch = {
    Name: string;
    ItemBranchAbbr: string;
    BranchId: number;
    populationServed: number;
    venueID: string;
    coordinates: string;
};

export type CollectionByMonth = {
    Period: string;
    Year: number;
    Month: number;
    Count: number;
};

export interface GetCollectionHistoryByCodes {
    expired: boolean;
    data: Collection[];
}

export interface Collection {
    CircCount?: number;
    CircPercent?: string;
    Collection: string;
    Abbr: string;
    Count: number;
    Percent?: string;
    DEIcatagory?: string;
}

export interface CircDate {
    Date: string;
    Count: number;
}
export interface GetCirculationByDate {
    expired: boolean;
    data: [CircDate[], { YearCirc: number }[], { YearCircGroup: number }[]];
}

export interface GetLocationCirculation {
    expired: boolean;
    data: LocationCirculation[];
}

export interface LocationCirculation {
    Name: string;
    Count: number;
}

const MaterialTypes = [
    { label: 'filters.common.all', value: 'ALL' },
    { label: 'filters.materialTypes.Print', value: 'Print' },
    { label: 'filters.materialTypes.Movie', value: 'Movie' },
    { label: 'filters.materialTypes.Ebook', value: 'Ebook' },
    { label: 'filters.materialTypes.Audiobook', value: 'Audiobook' },
    { label: 'filters.materialTypes.Library of Things', value: 'Library of Things' },
    { label: 'filters.materialTypes.Magazine', value: 'Magazine' },
    { label: 'filters.materialTypes.Music', value: 'Music' },
    { label: 'filters.materialTypes.Video Games', value: 'Video Games' },
    { label: 'filters.materialTypes.Technology', value: 'Technology' },
    { label: 'filters.materialTypes.kits', value: 'Kits' },
    { label: 'filters.materialTypes.Read Along', value: 'Read Along' },
    { label: 'filters.materialTypes.Other', value: 'Other' }
];

@Injectable({ providedIn: 'root' })
export class ReportService {
    currentLibrary = null;
    currentConsortium = null;
    inConsortiumID = null;
    currentAccountGroup = null;
    accountGroups: AccountGroup[] = [];
    consortiums: Consortium[] = [];
    libraries: Library[] = [];
    branches: Branch[] = [];
    currentBranch = null;
    currentBranchId: number | null = null;
    currentPeriod = null;
    criteriaChange = null;
    lastLibrary = -1;
    lastBranch = -1;
    collectionPerformance;
    periods: { name: string; id: string }[];
    searchBiblio: SearchBiblio = {
        lastLocation: '',
        start: '',
        end: '',
        collection: '',
        returnValue: null,
        deiCategory: ''
    };
    validBranch = [];

    monitorChange = new BehaviorSubject<MonitorChange>({
        currentLibrary: this.currentLibrary,
        currentBranch: this.currentBranch
    });
    $libraries = new BehaviorSubject<Library[]>([]);
    accountGroupChange$ = new BehaviorSubject<number|null>(null);
    consortiumChange$ = new BehaviorSubject<number|null>(null);

    constructor(private http: HttpClient) {
        this.periods = [
            { name: 'Annually', id: 'annually' },
            { name: 'Quarterly', id: 'quarterly' }
        ];
        this.currentPeriod = 'annually';
        this.collectionPerformance = {
            selectAges: [
                {
                    label: 'filters.common.all',
                    value: 'ALL'
                },
                {
                    label: 'filters.ages.Adult',
                    value: 'Adult'
                },
                {
                    label: 'filters.ages.Young Adult',
                    value: 'Young Adult'
                },
                {
                    label: 'filters.ages.Children',
                    value: 'Children'
                },
                {
                    label: 'filters.ages.Other',
                    value: 'Other'
                }
            ]
        };
        this.collectionPerformance.selectAge = {
            selected: this.collectionPerformance.selectAges[0].value
        };

        this.collectionPerformance.selectCategorys = [
            {
                label: 'filters.common.all',
                value: 'ALL'
            },
            {
                label: 'filters.categories.Fiction',
                value: 'Fiction'
            },
            {
                label: 'filters.categories.Nonfiction',
                value: 'Nonfiction'
            },
            {
                label: 'filters.categories.Other',
                value: 'Other'
            }
        ];
        this.collectionPerformance.selectCategory = {
            selected: this.collectionPerformance.selectCategorys[0].value
        };

        // Material Types are configured based on availability
        this.collectionPerformance.selectMatTypes = [{
            label: 'filters.common.all',
            value: 'ALL'
        }];
        this.collectionPerformance.selectMatType = {
            selected: this.collectionPerformance.selectMatTypes[0].value
        };

        this.criteriaChange = new BehaviorSubject<{
            currentAge: string;
            Category: string;
            MatType: string;
            deiCategory: string;
        }>({
            currentAge: this.collectionPerformance.selectAge,
            Category: this.collectionPerformance.selectCategory,
            MatType: this.collectionPerformance.selectMatType,
            deiCategory: this.collectionPerformance.deiCategory
        });

        this.monitorChange
            .pipe(
                distinctUntilChanged((prev, curr) => (
                    // only continue if the branch or the library actually changed
                    prev.currentBranch === curr.currentBranch &&
                    prev.currentLibrary === curr.currentLibrary
                )),
                filter((change) => !!change.currentBranch && !!change.currentLibrary),
                switchMap(() => this.getValidMatTypes()),
                map((res) => res.data.map((item) => item.MatType))
            )
            .subscribe((validMatTypes) => {
                const currentMatType = this.collectionPerformance.selectMatType.selected;
                this.collectionPerformance.selectMatType.selected = null;

                this.collectionPerformance.selectMatTypes = MaterialTypes.filter((type) =>
                    validMatTypes.includes(type.value)
                );

                this.collectionPerformance.selectMatTypes.unshift({
                    label: 'filters.common.all',
                    value: 'ALL'
                });

                this.collectionPerformance.selectMatTypes.forEach((element, index) => {
                    if (element.value === currentMatType) {
                        this.collectionPerformance.selectMatType = {
                            selected: this.collectionPerformance.selectMatTypes[index].value
                        };
                    }
                });

                // if we still don't have a selected, use the first
                if (!this.collectionPerformance.selectMatType.selected) {
                    this.collectionPerformance.selectMatType = {
                        selected: this.collectionPerformance.selectMatTypes[0].value
                    };
                }
            });
    }

    public capitalizeFirstLetter(input: string): string {
        return input && input.length ? input.charAt(0).toUpperCase() + input.slice(1).toLowerCase() : input;
    }

    public capitalizeSecondLetter(input: string): string {
        return input && input.length
            ? input.slice(0, 1).toLowerCase() + input.charAt(1).toUpperCase() + input.slice(2).toLowerCase()
            : input;
    }

    private userLog(message: string): void {
        const user = JSON.parse(localStorage['userSession']);
        if (!user || !user.userName) return;
        this.http
            .post('/api/login/userLog', {
                user: user.userName,
                message: message,
                libraryid: this.currentLibrary,
                branchid: this.currentBranch
            })
            .subscribe();
    }

    public resetCriteria(): void { 
        this.collectionPerformance.selectAge.selected = 'ALL';
        this.collectionPerformance.selectCategory.selected = 'ALL';
        this.collectionPerformance.selectMatType.selected = 'ALL';
    }

    public isCriteriaAll(): boolean {
        if (
            this.collectionPerformance.selectAge.selected === 'ALL' &&
            this.collectionPerformance.selectCategory.selected === 'ALL' &&
            this.collectionPerformance.selectMatType.selected === 'ALL'
        ) {
            return true;
        }
        return false;
    }

    public changeCriteria(): void {
        this.userLog('Change Criteria');
        this.criteriaChange.next({
            currentAge: this.collectionPerformance.selectAge,
            Category: this.collectionPerformance.selectCategory,
            MatType: this.collectionPerformance.selectMatType,
            period: this.currentPeriod,
            deiCategory: this.collectionPerformance.deiCategory
        });
    }

    public getBranchName(): string {
        let branchName = '';
        this.branches.forEach((branch) => {
            if (branch.ItemBranchAbbr === this.currentBranch) {
                branchName = branch.Name;
            }
        });
        return branchName;
    }

    public getLibraryName(libraryid = this.currentLibrary): string {
        let libName = '';
        this.libraries.forEach((library) => {
            if (library.id === libraryid) {
                libName = library.name;
            }
        });
        return libName;
    }

    public getCurrentLibrary(): Library {
        let lib: Library;
        if (!this.libraries.length) {
            // this.loadLibraries();
            return;
        }
        this.libraries.forEach((library) => {
            if (library.id === this.currentLibrary) {
                lib = library;
            }
        });
        if (!lib.fiscalYear) lib.fiscalYear = '1-1';
        return lib;
    }

    public resetGroups(): void {
        if (this.currentLibrary === -1) {
            if (this.libraries[0].id === -1) {
                this.libraries.shift();
            }
            this.$libraries.next(this.libraries);
            this.currentAccountGroup = 0;
            this.accountGroupChange$.next(0);
            this.currentConsortium = 0;
            this.consortiumChange$.next(0);
            this.currentBranch = 'ALL';
            this.changeLibrary({ id: this.libraries[0].id });
        }
    }

    public changeAccountGroup($event): void {
        if (!$event.value) {
            if (this.libraries[0].id === -1) {
                this.libraries.shift();
            }
            if (this.currentLibrary === -1) {
                this.changeLibrary({ id: 1 });
            }
            this.$libraries.next(this.libraries);
            this.accountGroupChange$.next($event.value);
            return;
        }
        if (this.libraries[0].id !== -1) {
            this.libraries.unshift({
                name: 'All',
                id: -1,
                fiscalYear: '',
                populationServed: 0,
                groupId: $event.value,
                settings: ''
            });
        } else {
            this.libraries[0].groupId = $event.value;
        }

        this.$libraries.next(this.libraries);
        this.accountGroupChange$.next($event.value);
        this.lastLibrary = -1;
        this.currentLibrary = -1;
        this.currentBranch = -1;

        this.userLog('Change Group');
        this.monitorChange.next({ currentLibrary: this.currentLibrary, currentBranch: this.currentBranch });
    }

    public changeConsortium($event): void {
        if (!$event.value) {
            if (this.libraries[0].id === -1) {
                this.libraries.shift();
            }
            if (this.currentLibrary === -1) {
                this.changeLibrary({ id: this.libraries[0].id });
            }
            this.$libraries.next(this.libraries);
            this.consortiumChange$.next($event.value);
            return;
        }

        if (this.libraries[0].id !== -1) {
            this.libraries.unshift({
                name: 'All',
                id: -1,
                fiscalYear: '',
                populationServed: 0,
                cid: $event.value,
                settings: ''
            });
        } else {
            this.libraries[0].cid = $event.value;
        }

        this.$libraries.next(this.libraries);
        this.consortiumChange$.next($event.value);
        this.lastLibrary = -1;
        this.currentLibrary = -1;
        this.currentBranch = -1;

        this.userLog('Change Consortium');
        this.monitorChange.next({ currentLibrary: this.currentLibrary, currentBranch: this.currentBranch });
    }

    private createConsortiums(groups): void {
        this.consortiums = [];
        let hash = {};
        this.libraries.forEach((library) => {
            if (library.cname && !hash[library.cname]) {
                hash[library.cname] = library;
                this.consortiums.push({
                    id: library.cid,
                    name: library.cname,
                    populationServed: library.cPopulationServed,
                    coordinates: library.cCoordinates
                });
            }
        });

        if (this.consortiums.length > 0) {
            this.consortiums.unshift({ id: 0, name: 'None', populationServed: null, coordinates: null });
            this.currentConsortium = 0;
        }
        if (this.consortiums.length === 2 && this.libraries.length === 1) this.consortiums = [];
        hash = {};
        groups.forEach((group) => {
            if (group.groupName && hash[group.groupName]) {
                this.accountGroups.forEach((ag, index) => {
                    if (ag.id === group.groupId && group.lgBranchId) {
                        this.accountGroups[index].branchId.push(group.lgBranchId);
                    }
                    if (ag.id === group.groupId && group.lgLibraryId) {
                        this.accountGroups[index].libraryId.push(group.lgLibraryId);
                    }
                });
            }
            if (group.groupName && !hash[group.groupName]) {
                hash[group.groupName] = group;
                this.accountGroups.push({
                    id: group.groupId,
                    name: group.groupName,
                    libraryId: group.lgLibraryId ? [-1, group.lgLibraryId] : [-1],
                    branchId: group.lgBranchId ? [group.lgBranchId] : [],
                    populationServed: group.lgPopulationServed,
                    coordinates: group.lgCoordinates
                });
            }
        });

        if (this.accountGroups.length > 0) {
            this.accountGroups.unshift({ id: 0, name: 'None', coordinates: null, populationServed: null });
            this.currentAccountGroup = 0;
        }
    }

    public loadLibraries(): void {
        this.getLibraries().subscribe(([libraries, groups]) => {
            this.libraries = libraries;
            this.$libraries.next(libraries);
            this.createConsortiums(groups);
            if (!this.currentLibrary) {
                this.changeLibrary(libraries[0]);
            }
        });
    }

    public changeLibrary($event, refresh = null): void {
        if (this.currentAccountGroup && $event.value === -1) {
            this.currentLibrary = -1;
            this.currentBranch = 'ALL';
            this.currentBranchId = 0;
            this.monitorChange.next({ currentLibrary: this.currentLibrary, currentBranch: this.currentBranch });
            return;
        }

        this.currentLibrary = $event.value;
        if (this.currentLibrary === undefined) {
            this.currentLibrary = $event.id || 32;
        }

        if (this.currentLibrary === this.lastLibrary && !refresh) return;
        this.inConsortiumID = this.getCurrentLibrary()?.cid;
        this.lastLibrary = this.currentLibrary;
        this.getBranchByLibrary(refresh).subscribe((branches) => {
            this.branches = branches.data;
            if (this.branches.length > 1) {
                this.branches.unshift({
                    Name: 'All',
                    ItemBranchAbbr: 'ALL',
                    BranchId: 0,
                    populationServed: null,
                    venueID: null,
                    coordinates: null
                });
            }
            if (this.validBranch.length > 0) {
                this.branches = this.branches.filter((branch) => {
                    if (this.validBranch.includes(branch.BranchId)) return true;
                    else return false;
                });
            }
            this.currentBranch = this.branches.length > 0 ? this.branches[0].ItemBranchAbbr : 'ALL';
            this.lastBranch = this.currentBranch;
            this.currentBranchId = this.getBranchId(this.currentBranch);
            if (this.currentAccountGroup && this.getCurrentAccountGroup().branchId.length) {
                this.currentBranchId = this.getFirstBranchInGroup();
                this.currentBranch = this.getBranchAbbr(this.currentBranchId);
            }
            this.userLog('Change Library');
            this.monitorChange.next({ currentLibrary: this.currentLibrary, currentBranch: this.currentBranch });
        });
    }

    public getFirstBranchInGroup(): number {
        const branchFound = this.branches.find((branch) => {
            if (this.getCurrentAccountGroup().branchId.includes(branch.BranchId)) return true;
            else return false;
        });
        if (branchFound) return branchFound.BranchId;
        else return null;
    }

    public getBranchId(branchAbbr: string): number {
        const branchFound = this.branches.find((branch) => {
            if (branch.ItemBranchAbbr === branchAbbr) return true;
            else return false;
        });
        if (branchFound) return branchFound.BranchId;
        else return null;
    }

    public getBranchAbbr(branchId: number): string {
        const branchFound = this.branches.find((branch) => {
            if (branch.BranchId === branchId) return true;
            else return false;
        });
        if (branchFound) return branchFound.ItemBranchAbbr;
        else return null;
    }

    public getBranchPopulationServed(): number {
        const branchFound = this.branches.find((branch) => {
            if (branch.ItemBranchAbbr === this.currentBranch) return true;
            else return false;
        });
        if (branchFound) return branchFound.populationServed;
        else return null;
    }

    public getBranchVenueID(): string {
        const branchFound = this.branches.find((branch) => {
            if (branch.ItemBranchAbbr === this.currentBranch) return true;
            else return false;
        });
        if (branchFound) return branchFound.venueID;
        else return null;
    }

    public getBranchCoordinates(): string {
        const branchFound = this.branches.find((branch) => {
            if (branch.ItemBranchAbbr === this.currentBranch) return true;
            else return false;
        });
        if (branchFound?.coordinates?.length) return JSON.parse(branchFound.coordinates);
        else return null;
    }

    public changeBranch(): void {
        if (this.currentBranch === this.lastBranch) return;
        this.lastBranch = this.currentBranch;
        if (!this.currentBranch?.length) this.currentBranchId = null;
        else {
            this.currentBranchId = this.getBranchId(this.currentBranch);
        }
        this.userLog('Change Branch');
        this.monitorChange.next({ currentLibrary: this.currentLibrary, currentBranch: this.currentBranch });
    }

    public getLibraries(): Observable<[Library[], []]> {
        return this.http.get<[Library[], []]>('/api/report/getLibraries').pipe(catchError(this.handleError));
    }

    public getUserLibraries(userId, accountId): Observable<any> {
        return this.http
            .get('/api/general/getAdminUserLibraries', {
                params: {
                    userId: userId,
                    accountId: accountId
                }
            })
            .pipe<any>(catchError(this.handleError));
    }

    public getAccountGroupBranchCode(): Observable<{ branchCode: string }[]> {
        return this.http
            .get<{ branchCode: string }[]>('/api/report/getAccountGroupBranchCode', {
            params: {
                accountGroup: this.currentAccountGroup
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getBranchByLibrary(refresh = null): Observable<{ expired: boolean; data: Branch[] }> {
        return this.http
            .get<{ expired: boolean; data: Branch[] }>('/api/report/getBranchByLibrary', {
            params: {
                library: this.currentLibrary,
                refresh
            }
        })
            .pipe(catchError(this.handleError));
    }

    // Required to handle any error type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleError(error: Response): Observable<any> {
        // localStorage.removeItem('currentLibrary');
        // localStorage.removeItem('currentBranch');
        console.error('ApiService::handleError', error);
        return throwError(error);
    }

    public getCirculationTrend(): Observable<GetCircByMonth> {
        return this.http
            .get<GetCircByMonth>('/api/report/getCircByMonth', {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCurrentAccountGroup(): AccountGroup | null {
        let returnedGroup = null;
        this.accountGroups.find((account) => {
            if (account.id === this.currentAccountGroup) returnedGroup = account;
        });
        return returnedGroup;
    }

    public getCurrentConsortium(): Consortium | null {
        let returnedConsortium = null;
        this.consortiums.find((account) => {
            if (account.id === this.currentConsortium) returnedConsortium = account;
        });
        return returnedConsortium;
    }

    public accountGroupBranchesValue(): string {
        return this.accountGroups?.length && this.getCurrentAccountGroup().branchId?.length
            ? JSON.stringify(this.getCurrentAccountGroup().branchId)
            : '[]';
    }

    public getCirculationInHouseTrend(): Observable<{ expired: boolean; data: CirculationTrend[] }> {
        return this.http
            .get<{ expired: boolean; data: CirculationTrend[] }>('/api/inHouseStats/getCircInHouseByMonth', {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCollectionCreatedByMonth(): Observable<{ expired: boolean; data: CollectionByMonth[] }> {
        const apiUrl = this.collectionPerformance.deiCategory
            ? '/api/report/getDEICollectionHistoryCreatedByMonth'
            : '/api/report/getCollectionCreatedByMonth';
        return this.http
            .get<{ expired: boolean; data: CollectionByMonth[] }>(apiUrl, {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected,
                DEICategory: this.collectionPerformance.deiCategory ? this.collectionPerformance.deiCategory : ''
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCollectionHistoryCollectionCode(): Observable<GetCollectionHistoryByCodes> {
        const apiUrl = this.collectionPerformance.deiCategory
            ? '/api/report/getDEICollectionHistoryCollectionCode'
            : '/api/report/getCollectionHistoryCollectionCode';
        return this.http
            .get<GetCollectionHistoryByCodes>(apiUrl, {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected,
                DEICategory: this.collectionPerformance.deiCategory ? this.collectionPerformance.deiCategory : ''
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getValidMatTypes(): Observable<any> {
        const apiUrl = '/api/report/getValidMatTypes';
        return this.http
            .get<any>(apiUrl, {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCollectionHistoryForDateRange(start, end): Observable<{ expired: boolean; data: WeedRecord[] }> {
        const url = this.searchBiblio.deiCategory
            ? '/api/report/getDEICollectionHistoryForDateRange'
            : '/api/report/getCollectionHistoryForDateRange';
        return this.http
            .get<{ expired: boolean; data: WeedRecord[] }>(url, {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                start: start,
                end: end,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected,
                DEICategory: this.searchBiblio.deiCategory ? this.searchBiblio.deiCategory : ''
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCircReportAll(): Observable<{ expired: boolean; data: CircDetailAll[] }> {
        return this.http
            .get<{ expired: boolean; data: CircDetailAll[] }>('/api/report/getCircReportAll', {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCircReportDetail(): Observable<{ expired: boolean; data: CircDetail[] }> {
        return this.http
            .get<{ expired: boolean; data: CircDetail[] }>('/api/report/getCircReportDetail', {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getCollectionAgeBreakdown(): Observable<any> {
        return this.http
            .get('/api/report/getCollectionAgeBreakdown', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getFrequencyByCollection(): Observable<any> {
        return this.http
            .get('/api/report/getFrequencyByCollection', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected,
                    period: this.currentPeriod
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getCirculationByDate(): Observable<GetCirculationByDate> {
        return this.http
            .get<GetCirculationByDate>('/api/report/getCirculationByDate', {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected
            }
        })
            .pipe(catchError(this.handleError));
    }

    public getHoldsByDate(): Observable<any> {
        return this.http
            .get('/api/report/getHoldsByDate', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getNewItemUtilization(): Observable<any> {
        return this.http
            .get('/api/report/getNewItemUtilization', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected,
                    period: this.currentPeriod
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getNewItemUtilizationPattern(): Observable<any> {
        return this.http
            .get('/api/report/getNewItemUtilizationPattern', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected,
                    period: this.currentPeriod
                }
            })
            .pipe(catchError(this.handleError));
    }


    public getCircForDateRange(start, end): Observable<any> {
        return this.http
            .get('/api/report/getCircForDateRange', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    start: start,
                    age: this.collectionPerformance.selectAge.selected,
                    collection: this.searchBiblio.collection ? this.searchBiblio.collection : 'ALL',
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected,
                    end: end
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getCircInHouseForDateRange(start, end): Observable<any> {
        return this.http
            .get('/api/inHouseStats/getCircInHouseForDateRange', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    start: start,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected,
                    end: end
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getCircForDate(start): Observable<any> {
        return this.http
            .get('/api/report/getCircForDate', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    date: start,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getHoldsForDate(start): Observable<any> {
        return this.http
            .get('/api/report/getHoldsForDate', {
                params: {
                    consortium: this.currentConsortium,
                    accountGroup: this.currentAccountGroup,
                    accountGroupBranches: this.accountGroupBranchesValue(),
                    library: this.currentLibrary,
                    branch: this.currentBranch,
                    date: start,
                    age: this.collectionPerformance.selectAge.selected,
                    category: this.collectionPerformance.selectCategory.selected,
                    matType: this.collectionPerformance.selectMatType.selected
                }
            })
            .pipe(catchError(this.handleError));
    }

    public getLocationCirculation(collection: string): Observable<GetLocationCirculation> {
        return this.http
            .get<GetLocationCirculation>('/api/report/getLocationCirculation', {
            params: {
                consortium: this.currentConsortium,
                accountGroup: this.currentAccountGroup,
                accountGroupBranches: this.accountGroupBranchesValue(),
                library: this.currentLibrary,
                branch: this.currentBranch,
                age: this.collectionPerformance.selectAge.selected,
                category: this.collectionPerformance.selectCategory.selected,
                matType: this.collectionPerformance.selectMatType.selected,
                collection: collection
            }
        })
            .pipe(catchError(this.handleError));
    }

    exportCSVData(name, grid): void {
        if (this.currentLibrary === 666) {
            alert('Export is disabled in the Sandbox');
            return;
        }
        let branch = this.getBranchName();
        if (branch === 'All') branch = 'System';
        const fileName = name + '_' + this.getLibraryName() + '_' + branch + '.csv';
        grid.api.exportDataAsCsv({
            fileName: fileName,
            onlyVisible: true,
            processCellCallback: (params): string => {
                if (params.column.getColId() === 'itemcreationdate') {
                    if (dayjs(params.node.data.itemcreationdate).format('YYYY-MM-DD') === 'Invalid Date') return '';
                    return dayjs(params.node.data.itemcreationdate).format('YYYY-MM-DD');
                }
                if (params.column.getColId() === 'lastcheckoutrenewdate') {
                    if (!params.node.data.lastcheckoutrenewdate && params.node.data.itemlifetimecirccount > 0)
                        return 'Unknown';
                    if (!params.node.data.lastcheckoutrenewdate) return 'Never';
                    if (dayjs(params.node.data.lastcheckoutrenewdate).format('YYYY-MM-DD') === 'Invalid Date')
                        return '';
                    return dayjs(params.node.data.lastcheckoutrenewdate).utc().format('YYYY-MM-DD');
                }
                if (params.column.getColId() === 'scanDate') {
                    if (dayjs(params.node.data.scanDate).format('YYYY-MM-DD') === 'Invalid Date') return '';
                    return dayjs(params.node.data.scanDate).format('YYYY-MM-DD');
                }
                if (params.column.getColId() === 'Circulates') {
                    if (params.node.data.Circulates) return 'Yes';
                    else return 'No';
                }
                if (params.column.getColId() === 'keepSetting') {
                    if (params.node.data.keepSetting) return 'Keep';
                    else return '';
                }
                return params.value;
            }
        });
    }

    saveGridState(grid: GridOptions, gridName: string): void {
        let gridState = null;
        try {
            gridState = JSON.parse(localStorage.getItem(gridName + 'GridState'));
        } catch (e) {
            gridState = null;
        }

        if (gridState) {
            grid.columnApi.applyColumnState({ state: gridState, applyOrder: true });
        } else {
            grid?.api.addEventListener('firstDataRendered', () => {
                const colIds = grid?.columnApi.getAllDisplayedColumns().map((col) => {
                    return col.getColId();
                });
                grid?.columnApi.autoSizeColumns(colIds);
            });
        }

        grid.api.addGlobalListener((type, event) => {
            const columnEvents = [
                'displayedColumnsChanged',
                'columnEverythingChanged',
                'columnVisible',
                'sortChanged',
                'columnMoved',
                'columnResized'
            ];

            if (columnEvents.includes(type) || type.indexOf('column') !== -1) {
                if (!grid) return;
                if (grid.api['destroyCalled']) return;
                localStorage.setItem(gridName + 'GridState', JSON.stringify(grid.columnApi.getColumnState()));
            }
            if (['filterModified', 'filterChanged'].includes(type)) {
                if (!grid) return;
                if (grid.api['destroyCalled']) return;
                localStorage.setItem(gridName + 'GridFilterState', JSON.stringify(grid.api.getFilterModel()));
            }
        });
    }

    restoreGridState(grid: GridOptions, gridName: string): void {
        const gridFilterState = localStorage.getItem(gridName + 'GridFilterState');
        if (gridFilterState) grid.api.setFilterModel(JSON.parse(gridFilterState));
    }
}
