import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
  
@Component({
    selector: 'liq-doc360',
    standalone: true,
    template: `
        <button *ngIf="showButton$ | async" class="doc360-button" (click)="openWidget()">
            <img src="/assets/icons/svgs/Document360.svg">
        </button>
    `,
    styles: [`
        .doc360-button {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 100000;
            background-color: #ab2180;
            border: none;
            height: 45px;
            width: 45px;
        }

        .doc360-button img {
            width: 30px;
        }
    `],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiqDoc360Component implements OnInit {
    private document: Document = inject(DOCUMENT);
    private window = this.document.defaultView;

    showButton$ = new BehaviorSubject(false);

    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.showButton$.subscribe(() => {
            // for some reason, the change on its own is not causing the UI
            // to rerender in all cases, mainly on the login page.
            setTimeout(() => this.cdRef.detectChanges());
        });

        this.window['doc360_callback'] = this.initCallback.bind(this);
        this.window['doc360_show_callback'] = this.showCallback.bind(this);
        this.window['doc360_hide_callback'] = this.hideCallback.bind(this);

        const loaded = !!this.document.querySelector('#doc360');
        if (!loaded) {
            const script = this.document.createElement('script');
            script.id = 'doc360';
            script.innerHTML = `
                (function (w,d,s,o,f,js,fjs) {
                    w['JS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
                }(window, document, 'script', 'mw', 'https://cdn.document360.io/static/js/widget.js'));
                mw('init', { apiKey: '${environment.doc360APIKey}', callback: doc360_callback, show_callback: doc360_show_callback, hide_callback: doc360_hide_callback });
            `;
            this.document.body.appendChild(script);
        }
    }

    initCallback(): void {
        this.showButton$.next(true);
    }

    showCallback(): void {
        this.showButton$.next(true);
    }

    hideCallback(): void {
        this.showButton$.next(false);
    }

    openWidget(): void {
        const frame = this.document.getElementById('document360-widget-iframe') as HTMLIFrameElement;
        frame.contentDocument.getElementById('doc360-button').click();
    }
}
